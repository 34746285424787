.skills {
  padding: 0;
  flex-wrap: wrap;
}
.skills li {
  list-style-type: none;
  background-color: var(--blue);
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
  margin: 8px;
  padding: 8px 16px;
}
