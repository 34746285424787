social-links {
  padding-top: 15px;
}

.social-link-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-top: 5px;
}

.social-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-links a {
  display: inline-block;
  padding: 10px;
  font-size: 17px;
}

.img-linkedin {
  margin-left: 9px;
}

.img-social-image {
  display: block;
  max-width: 100px;
  max-height: 65px;
  width: auto;
  height: auto;
}
