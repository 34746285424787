.introduction-name {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  color: var(--dark-grey);
}

.heading {
  font-size: 32px;
  margin: 0;
  font-weight: 400;
}

.heading-hr {
  height: 2px;
}

.heading.span {
  margin: 0 5px;
}

.introduction-picture {
  border-radius: 50%;
}

.introduction-separator {
  margin: 0 5px;
}

.github-link {
  font-weight: 600;
}
