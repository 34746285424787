:root {
  --background-color-white: #fbfafa;
  --blue: #4352ad;
  --dark-grey: #4e4e4e;
}

body {
  background-color: var(--background-color-white);
}

h2 {
  font-weight: 400;
  font-size: 1.8rem;
  margin-bottom: 18px;
}

a {
  font-size: 20px;
  color: var(--blue);
  text-decoration: none;
}

a:hover {
  color: var(--blue);
  text-decoration: underline;
}

.main {
  margin: 0 auto;
  max-width: 1100px;
  padding: 20px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.display-inline {
  display: flex;
  flex-direction: row;
}

.display-column {
  display: flex;
  flex-direction: column;
}

.display-row {
  display: flex;
  flex-direction: row;
}

hr {
  width: 100%;
  border: 0;
  border-top: 1px solid rgb(203 203 203 / 50%);
  margin: 17px 0;
}
