.imprint {
  max-width: 1000px;
  padding: 20px;
  margin: 0 auto;
}

.imprint-go-back-link {
  font-size: 24px;
  font-weight: 700;
}
